<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button
						type="text"
						size="small"
						@click="back"
						>返回</el-button>
				</p>
				<div class="h-b">
					<el-button
						type="primary"
						size="small"
						@click="add"
						v-right-code="'Tenantproduct:Save'"
						>新增</el-button
					>
				</div>
				<div class="h-b">
					<el-button
						type="text"
						size="small"
						@click="save"
						v-right-code="'Tenantproduct:Save'"
						>保存</el-button
					>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form
				:model="dataSource"
				ref="basicForm"
				:rules="baseCheckRule"
			>
			<el-form-item>
							<el-col :span="3" class="form-title">系统类型：</el-col>
							<el-col :span="9">
								<el-form-item prop="SystemType">
									<DictionarySelect 
										v-model="dataSource.SystemType" 
										datType='Int' 
										dicTypeCode="SystemTypeCode">
									</DictionarySelect>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 中文名称：</el-col>
							<el-col :span="9">
								<el-form-item prop="ChineseName">
									<el-input v-model="dataSource.ChineseName" placeholder="中文名称"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"><span style="color: red">*</span> 英文名称：</el-col>
							<el-col :span="9">
								<el-form-item prop="EnglishName">
									<el-input v-model="dataSource.EnglishName" placeholder="英文名称"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 排序号：</el-col>
							<el-col :span="9">
								<el-form-item prop="Sort">
									<el-input v-model="dataSource.Sort"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">是否外部系统：</el-col>
							<el-col :span="9">
								<el-form-item prop="IsExternal">
									<el-switch v-model="dataSource.IsExternal"></el-switch>
								</el-form-item>
							</el-col>
							<el-col :span="3" class="form-title" >站点地址：</el-col>
							<el-col :span="9">
								<el-form-item prop="AppUrl">
									<el-input v-model="dataSource.AppUrl"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title">备注：</el-col>
							<el-col :span="21">
								<el-form-item prop="Remark">
									<el-input type="textarea" v-model="dataSource.Remark" rows="3"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1',''],
                baseCheckRule: {
                    ChineseName:[{ required: true, message: '不能为空', trigger: 'blur' }],
                    EnglishName: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    SystemType: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    Sort: [{ required: true, message: '不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/tenantproduct/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/tenantproduct/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
        },
        components: {
        }
    }
</script>